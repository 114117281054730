.App {
  text-align: left;
  min-height: 89vh;
  
}

.Body {


  
  margin: 0;
  background-color:  #f5f5f5;
  
 
  
}


.pageContent {
  text-align: center;
  background-color: "white",
}


.pageContentLeft {
  text-align: left;
  font-size: 16px;
}

 

.pageContentSmall {
  text-align: center;
  font-size: 14px;
  margin-left: 5%;
  margin-right: 5%; 
}

.pageContentSmallLeft {
  text-align: left;
  font-size: 14px;
  
  
}


.pageContentSmall2 {
  text-align: left;
  font-size: 14px;
  margin-left: 5%;
  margin-right: 5%;
  margin-top: 0%;
}

.pageContentMed {
  text-align: center;
  font-size: 23px;
}

.pageContentMed2 {
  text-align: center;
  font-size: 23px;
  color: white;
  margin-left: 2%;
  margin-right: 2%;
}




.App-header {
  background-color: #d12121;
  
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: calc(8px + 2vh);
  color: white;
}

.App-link {
  color: #a8530e;
}


.App-link-button {
  
  background-color: #00091d;
  color: #ffffff;
  border: none;
  padding-left: 4vw;
  padding-right: 4vw;
}


.emailLink {
  
  top :0;
  margin: 10px;
  font-size: 18px;
  color: white;
}


.navBarLink {
  position : sticky;
  top :0;
  margin: 10px;
  font-size: 24px;
  color: white;

}

.navBarLinkActive {
  position : sticky;
  top :0;
  margin: 10px;
  font-size: 24px;
  color: rgb(252, 239, 66);
 
}


.navBarTitle {
  margin: 0vw;
  font-size: calc(26px + 2vh);
  position : sticky;
  top :0;
  color: white;
  padding-right: Calc(3vw - 10px);
}

.navBarBox {
  margin: 0px;
  padding-right: 3vw;
  padding-left: 3vw;
  font-size: calc(26px + 2vh);
  position : sticky;
  top :0;
  color: rgb(0, 0, 0);
  background-color:  #00091d;
  z-index: 10;
}

.footBox {
  margin: 0px;
  font-size: calc(4px + 1vh);
  text-align: center;
  min-height: 11vh;
  color: rgb(172, 172, 172);
  background-color:  #2b2b2b;
  bottom: 0;
 }


.divBox {
  margin: 0px;
  font-size: 3px;
  text-align: center;
  bottom : 0;
  color: rgb(0, 0, 0);
  background-color:  #2b2b2b; 
  
}

.divBox2 {
  margin: 0px;
  font-size: 3px;
  text-align: center;
  bottom : 0;
  color: rgb(0, 0, 0);
  background-color:  #ffffff; 
  
}


.divBox3 {
  margin: 0px;
  font-size: 1px;
  text-align: center;
  bottom : 0;
  color: rgb(0, 0, 0);
  background-color:  #000000; 
  
}

.divBox4 {
  margin: 0px;
  font-size: 1px;
  text-align: center;
  bottom : 0;
  color: rgb(0, 0, 0);
  background-color:  #000000; 
  box-shadow: inset 0 0 0 1px #2b2b2b;
  
}

.list {
text-align: left;
list-style-position: inside;
margin-left: 1%;
margin-right: 1%;
}

.contentBox {
  opacity: 1;
  background-color: rgb(255, 255, 255);
  
  }

  .contentSide {
    background-color: #00091d;
    min-height: 62.2vh;
    min-width: calc(9.6vw - 35px);
    opacity: 1;
    

    }

    .contentSide2 {
      background-color:  #000e2e;
      min-height: 62.2vh;
      min-width: calc(9.6vw - 35px );
      opacity: 1;
      
  
      }


.App-logo {
  height: 40px;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
